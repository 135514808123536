'use client';

/* eslint-disable no-case-declarations */

import { FC, useMemo, ReactNode, useContext, useReducer, createContext } from 'react';

import { IGift } from 'src/types/gift';
import { CartInterface } from 'src/types/cart';
import { IMerchant } from 'src/types/merchant';
import { ISMBAccount } from 'src/types/smbAccount';
import { IVirtualCard } from 'src/types/club-cards';
import { Ib2bProduct, ICategoryItem } from 'src/types/b2b';
import { IIssuedCard, IOrder } from 'src/types/order';
import { PAYMENTH_METHODS, CheckoutStateInterface } from 'src/types/checkout';
import { ITag } from 'src/types/tag';
import { INetwork } from 'src/types/networks';
import { IApartment } from 'src/types/apartment';
import { ICustomerCityPeople } from 'src/types/customer-citypeople';
import {
  CustomerHistoryTransaction,
  SupplierHistoryTransaction,
} from 'src/types/history-city-people';
import { IProjectCityPeople } from '../types/project-citypeople';

export enum ActionTypes {
  GET_BASKET = 'GET_BASKET',
  CHECKOUT_NEXT_STEP = 'CHECKOUT_NEXT_STEP',
  CHECKOUT_PREV_STEP = 'CHECKOUT_PREV_STEP',
  CHECKOUT_FIRST_STEP = 'CHECKOUT_FIRST_STEP',
  CHECKOUT_COMPLETE = 'CHECKOUT_COMPLETE',
  CHECKOUT_COMPLETE_ERROR = 'CHECKOUT_COMPLETE_ERROR',

  SET_SMB_ACCOUNT = 'SET_SMB_ACCOUNT',
  CHANGE_CART_AMOUNT = 'CHANGE_CART_AMOUNT',
  CLEAR_CART = 'CLEAR_CART',
  GET_MERCHANT = 'GET_MERCHANT',
  SET_SELECTED_MERCHANT = 'SET_SELECTED_MERCHANT',
  GET_PRODUCTS = 'GET_PRODUCTS',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  SEARCH_PRODUCTS = 'SEARCH_PRODUCTS',
  LOADING_SEARCH_PRODUCTS = 'LOADING_SEARCH_PRODUCTS',
  GET_BANNER_PRODUCTS = 'GET_BANNER_PRODUCTS',
  GET_MERCHANTS = 'GET_MERCHANTS',
  GET_MERCHANTS_TAGS = 'GET_MERCHANTS_TAGS',
  GET_MERCHANTS_CATEGORIES = 'GET_MERCHANTS_CATEGORIES',
  GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY',
  SET_RESERV_PRODUCTS = 'SET_RESERV_PRODUCTS',
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_PRODUCTS_TAGS = 'GET_PRODUCTS_TAGS',
  PUSH_CATEGORY_COLLECTOR = 'PUSH_CATEGORY_COLLECTOR',
  PUSH_CATEGORY_ID = 'PUSH_CATEGORY_ID',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  GET_PRODUCTS_SEARCH = 'GET_PRODUCTS_SEARCH',
  SET_PRODUCT_SETTINGS = 'SET_PRODUCT_SETTINGS',

  // -- orders
  GET_ORDERS = 'GET_ORDERS',
  GET_ORDERS_HISTORY = 'GET_ORDERS_HISTORY',
  GET_CLIENTS_LIST = 'GET_CLIENTS_LIST',
  SET_FILTERED_ORDERS_HISTORY = 'SET_FILTERED_ORDERS_HISTORY',
  SET_NEW_ORDER = 'SET_NEW_ORDER',
  SET_SELECTED_ORDER = 'SET_SELECTED_ORDER',
  SET_UPDATED_ORDER = 'SET_UPDATED_ORDER',
  SET_ORDER_COST = 'SET_ORDER_COST',
  ADD_ORDER = 'ADD_ORDER',
  CHANGE_ORDER_PRODUCT = 'CHANGE_ORDER_PRODUCT',
  SET_VIRTUAL_CARD = 'SET_VIRTUAL_CARD',
  SET_DELIVERY = 'SET_DELIVERY',
  GET_CARDS = 'GET_CARDS',
  ADD_ORDER_IMAGES = 'ADD_ORDER_IMAGES',

  // -- modals
  OPEN_MODAL_PRICE = 'OPEN_MODAL_PRICE',
  TOP_UP_BALANCE = 'TOP_UP_BALANCE',
  SET_SEARCH = 'SET_SEARCH',
  ENABLE_PRELOADER = 'ENABLE_PRELOADER',
  DISABLE_PRELOADER = 'DISABLE_PRELOADER',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
  OPEN_LOGIN_FORM = 'OPEN_LOGIN_FORM',
  CLOSE_LOGIN_FORM = 'CLOSE_LOGIN_FORM',

  // --virtual cards
  GET_VIRTUAL_CARD = 'GET_VIRTUAL_CARD',
  GET_ALL_VIRTUAL_CARD = 'GET_ALL_VIRTUAL_CARD',
  USE_VIRTUAL_CARD = 'USE_VIRTUAL_CARD',
  CLEAR_USED_VIRTUAL_CARDS = 'CLEAR_USED_VIRTUAL_CARDS',
  SET_SELECTED_VIRTUAL_CARD = 'SET_SELECTED_VIRTUAL_CARD',

  // -- gifts
  GET_GIFTS = 'GET_GIFTS',
  SELECT_GIFT_SUCCESS = 'SELECT_GIFT_SUCCESS',

  // --club cards
  GET_CLUB_CARD = 'GET_CLUB_CARD',

  // --modals
  SET_ACTIVE_DIALOG = 'SET_ACTIVE_DIALOG',

  // --category
  SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY',
  SET_ACTIVE_CATEGORIES_FROM_REVERSE = 'SET_ACTIVE_CATEGORIES_FROM_REVERSE',

  SET_COMMUNICATION = 'SET_COMMUNICATION',

  SET_OPEN_PRICE_PRODUCTS = 'SET_OPEN_PRICE_PRODUCTS',
  SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER = 'SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER',

  GET_NETWORKS = 'GET_NETWORKS',
  GET_NETWORKS_TAGS = 'GET_NETWORKS_TAGS',
  SET_FILTER_QUERY = 'SET_FILTER_QUERY',
  ADD_GREETING_IMAGE = 'ADD_GREETING_IMAGE',

  // --apartments (citypeople)
  GET_APARTMENTS = 'GET_APARTMENTS',
  SET_SELECTED_APARTMENT = 'SET_SELECTED_APARTMENT',
  GET_APARTMENT_VOUCHERS = 'GET_APARTMENT_VOUCHERS',

  // --projects (citypeople)
  GET_PROJECT_VOUCHERS = 'GET_PROJECT_VOUCHERS',
  GET_PROJECT_ALL = 'GET_PROJECT_ALL',

  // --customer (citypeople)
  GET_CUSTOMER_BY_SSO_ID = 'GET_CUSTOMER_BY_SSO_ID',

  SET_LOCAL_VIRTUAL_CARD = 'SET_LOCAL_VIRTUAL_CARD',
}

type StateType = {
  cart: CartInterface[];
  customer: ICustomerCityPeople | null;
  apartments: IApartment[];
  apartmentsCount: number;
  selectedApartment: IApartment | null;
  products: Ib2bProduct[];
  localVirtualCards: IVirtualCard[];
  productsSearch: Ib2bProduct[];
  productsByCategory: Ib2bProduct[];
  categories: ICategoryItem[];
  productsTags: ITag[];
  productCount: number;
  productsSearchCount: number;
  loadingSearchProduct: boolean;
  search: {
    merchants: IMerchant[];
    networks: INetwork[];
    categories: ICategoryItem[];
    tags: ITag[];
  };
  reservProducts: Ib2bProduct[];
  orders: Array<unknown>;
  cards: {
    cards: IIssuedCard[];
    count: number;
  } | null;
  isPreloader: boolean;
  errorMessage: string;
  checkout: CheckoutStateInterface;
  virtualCards: IVirtualCard[];
  allVirtualCards: IVirtualCard[];
  isLoginForm: boolean;
  categoryIdsCollector: string[];
  newOrder: IOrder | null;
  selectedOrder: IOrder | null;
  updatedOrder: IOrder | null;
  orderHistory: CustomerHistoryTransaction[] | SupplierHistoryTransaction[];
  clientsList: IMerchant[];
  projects: IProjectCityPeople[];
  filteredOrderHistory: CustomerHistoryTransaction[] | SupplierHistoryTransaction[];
  orderImages: string[];
  activeDialog: string | null;
  gifts: IGift[];
  merchants: IMerchant[];
  merchantsTags: ITag[];
  merchantsCategories: ICategoryItem[];
  networks: INetwork[];
  networksTags: ITag[];
  merchant: IMerchant[]; // TODO: check if used (and other properties) and remove if not
  merchantCount: number;
  networksCount: number;
  greetingImage: string;
  bannerProducts: Array<Ib2bProduct>;
  bannerProductCount: number;
  selectedProduct: Ib2bProduct | null;
  selectedVirtualCard: IVirtualCard | null;
  orderCost: number;
  selectedMerchant: IMerchant | null;
  smbAccount: ISMBAccount;
  activeCategory: any;
  activeCategoriesFromReverse: any;
  communicationWithReverse: boolean;
  communicationWithProducts: boolean;
  openPricesProducts: {
    price: number;
    title: string;
    value: string;
  }[];
  openPricesProductsFromBuilder: {
    price: number;
    title: string;
    value: string;
  }[];
  filterQuery: string;
  entityType: string;
};

export type ActionType = {
  type: ActionTypes;
  payload: any;
};

const INITIAL_STATE: StateType = {
  cart: [],
  products: [],
  customer: null,
  apartments: [],
  apartmentsCount: 0,
  selectedApartment: null,
  bannerProducts: [],
  merchants: [],
  merchantsTags: [],
  merchantsCategories: [],
  networks: [],
  networksTags: [],
  merchant: [],
  selectedProduct: null,
  productsSearch: [],
  productsSearchCount: 0,
  loadingSearchProduct: true,
  search: {
    merchants: [],
    categories: [],
    networks: [],
    tags: [],
  },
  productsByCategory: [],
  reservProducts: [],
  orders: [],
  cards: null,
  isPreloader: false,
  errorMessage: '',
  productCount: 0,
  bannerProductCount: 0,
  merchantCount: 0,
  networksCount: 0,
  greetingImage: '',
  checkout: {
    completed: false,
    activeStep: 0,
    billing: {},
    paymentMethod: '',
    clubCardId: '',
    deliveryMethod: '',
    deliveryCost: 0,
    usedVirtualCards: [],
    isPaymentFailed: false,
  },
  categories: [],
  productsTags: [],
  virtualCards: [],
  allVirtualCards: [],
  selectedVirtualCard: null,
  isLoginForm: false,
  categoryIdsCollector: [],
  newOrder: null,
  selectedOrder: null,
  updatedOrder: null,
  orderCost: 0,
  orderHistory: [],
  clientsList: [],
  projects: [],
  filteredOrderHistory: [],
  orderImages: [],
  activeDialog: null,
  gifts: [],
  selectedMerchant: null,
  smbAccount: {} as ISMBAccount,
  activeCategory: [],
  activeCategoriesFromReverse: [],
  communicationWithReverse: false,
  communicationWithProducts: false,
  openPricesProducts: [],
  openPricesProductsFromBuilder: [],
  filterQuery: '',
  entityType: '',
  localVirtualCards: [],
};

interface ContextProps {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}

const AppContext = createContext<ContextProps>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

const reducer = (state: StateType, action: ActionType): StateType => {
  switch (action?.type) {
    case ActionTypes.SET_SMB_ACCOUNT:
      return { ...state, smbAccount: action.payload.smbAccount };
    case ActionTypes.SET_SELECTED_MERCHANT:
      return { ...state, selectedMerchant: action.payload };
    case ActionTypes.SET_SELECTED_VIRTUAL_CARD:
      return { ...state, selectedVirtualCard: action.payload };
    case ActionTypes.SET_SELECTED_APARTMENT:
      return { ...state, selectedApartment: action.payload };
    case ActionTypes.SET_SELECTED_ORDER:
      return { ...state, selectedOrder: action.payload };
    case ActionTypes.SET_UPDATED_ORDER:
      return { ...state, updatedOrder: action.payload };

    // -- basket
    case ActionTypes.GET_BASKET:
      return { ...state, cart: action.payload };
    case ActionTypes.GET_VIRTUAL_CARD:
      return { ...state, virtualCards: action.payload };
    case ActionTypes.GET_ALL_VIRTUAL_CARD:
      return { ...state, allVirtualCards: action.payload };
    case ActionTypes.CHECKOUT_NEXT_STEP:
      return {
        ...state,
        checkout: { ...state.checkout, activeStep: state.checkout.activeStep + 1 },
      };
    case ActionTypes.CHECKOUT_PREV_STEP:
      return {
        ...state,
        checkout: { ...state.checkout, activeStep: state.checkout.activeStep - 1 },
      };
    case ActionTypes.CHECKOUT_FIRST_STEP:
      return {
        ...state,
        checkout: { ...state.checkout, activeStep: 0 },
      };
    case ActionTypes.CHECKOUT_COMPLETE:
      return {
        ...state,
        checkout: { ...state.checkout, completed: true },
      };
    case ActionTypes.CHECKOUT_COMPLETE_ERROR:
      return {
        ...state,
        checkout: { ...state.checkout, completed: true, isPaymentFailed: true },
      };
    case ActionTypes.USE_VIRTUAL_CARD:
      const { id, cost } = action.payload;

      const stateUsedVirtualCards = [...state.checkout.usedVirtualCards];
      const stateUsedVirtualCardsIds = state.checkout.usedVirtualCards.map(
        (usedCard) => usedCard.id
      );

      if (!stateUsedVirtualCardsIds.includes(id)) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            usedVirtualCards: [...state.checkout.usedVirtualCards, action.payload],
          },
        };
      }

      const pos = stateUsedVirtualCardsIds.indexOf(id);

      if (cost === 0) {
        stateUsedVirtualCards.splice(pos, 1);
      } else {
        stateUsedVirtualCards.splice(pos, 1, action.payload);
      }

      return {
        ...state,
        checkout: {
          ...state.checkout,
          usedVirtualCards: stateUsedVirtualCards,
        },
      };
    case ActionTypes.CLEAR_USED_VIRTUAL_CARDS:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          usedVirtualCards: [],
        },
      };
    case ActionTypes.SET_VIRTUAL_CARD:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          paymentMethod: PAYMENTH_METHODS.clubCard,
          clubCardId: action.payload,
        },
      };
    case ActionTypes.SET_DELIVERY:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          deliveryMethod: action.payload.method,
          deliveryCost: action.payload.cost,
        },
      };
    case ActionTypes.CLEAR_CART:
      return {
        ...state,
        cart: [],
        checkout: INITIAL_STATE.checkout,
      };

    // -- product
    case ActionTypes.GET_PRODUCTS:
      return { ...state, products: action.payload.products, productCount: action.payload.count };
    case ActionTypes.SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.payload };
    case ActionTypes.SEARCH_PRODUCTS:
      return {
        ...state,
        productsSearch: action.payload.products,
        productsSearchCount: action.payload.count,
        search: {
          ...state.search,
          categories: action.payload.categories,
          merchants: action.payload.merchants,
          networks: action.payload.networks,
          tags: action.payload.tags,
        },
      };
    case ActionTypes.LOADING_SEARCH_PRODUCTS:
      return {
        ...state,
        loadingSearchProduct: action.payload,
      };
    case ActionTypes.SET_ACTIVE_CATEGORY:
      return { ...state, activeCategory: action.payload.category };
    case ActionTypes.SET_COMMUNICATION:
      return {
        ...state,
        communicationWithReverse: action.payload.communicationWithReverse,
        communicationWithProducts: action.payload.communicationWithProducts,
      };
    case ActionTypes.SET_OPEN_PRICE_PRODUCTS:
      return {
        ...state,
        openPricesProducts: action.payload,
      };
    case ActionTypes.SET_OPEN_PRICE_PRODUCTS_FROM_BUILDER:
      return {
        ...state,
        openPricesProductsFromBuilder: action.payload,
      };
    case ActionTypes.GET_NETWORKS:
      return {
        ...state,
        networks: action.payload.networks,
        networksCount: action.payload.count,
      };
    case ActionTypes.GET_NETWORKS_TAGS:
      return {
        ...state,
        networksTags: action.payload.networksTags,
      };
    case ActionTypes.SET_FILTER_QUERY:
      return {
        ...state,
        filterQuery: action.payload.searchQuery,
        entityType: action.payload.type,
      };
    case ActionTypes.ADD_GREETING_IMAGE:
      return {
        ...state,
        greetingImage: action.payload,
      };
    case ActionTypes.SET_ACTIVE_CATEGORIES_FROM_REVERSE:
      // eslint-disable-next-line no-case-declarations
      const categoryIdToAdd = action.payload.category;
      if (!state.activeCategoriesFromReverse.includes(categoryIdToAdd)) {
        return {
          ...state,
          activeCategoriesFromReverse: [...state.activeCategoriesFromReverse, categoryIdToAdd],
        };
      }
      return state;
    case ActionTypes.GET_BANNER_PRODUCTS:
      return {
        ...state,
        bannerProducts: action.payload.products,
        bannerProductCount: action.payload.count,
      };
    case ActionTypes.GET_MERCHANTS:
      return { ...state, merchants: action.payload.merchants, merchantCount: action.payload.count };
    case ActionTypes.GET_MERCHANTS_TAGS:
      return { ...state, merchantsTags: action.payload.merchantsTags };
    case ActionTypes.GET_MERCHANTS_CATEGORIES:
      return { ...state, merchantsCategories: action.payload.merchantsCategories };
    case ActionTypes.GET_MERCHANT:
      return { ...state, merchant: action.payload.merchant };
    case ActionTypes.GET_PRODUCTS_SEARCH:
      return {
        ...state,
        productsSearch: action.payload.products,
        productsSearchCount: action.payload.count,
      };
    case ActionTypes.GET_PRODUCTS_BY_CATEGORY:
      return { ...state, productsByCategory: action.payload.productsByCategory };
    case ActionTypes.SET_RESERV_PRODUCTS:
      return { ...state, reservProducts: action.payload.products };
    case ActionTypes.GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case ActionTypes.GET_PRODUCTS_TAGS:
      return { ...state, productsTags: action.payload };
    case ActionTypes.PUSH_CATEGORY_ID: {
      const _categoryIds = [...state.categoryIdsCollector];
      _categoryIds.push(action.payload);
      return { ...state, categoryIdsCollector: _categoryIds };
    }
    case ActionTypes.PUSH_CATEGORY_COLLECTOR: {
      let _categoryIds = [...state.categoryIdsCollector];
      if (!_categoryIds.includes(action.payload)) {
        _categoryIds.push(action.payload);
      } else {
        _categoryIds = _categoryIds.filter((categoryId: string) => categoryId !== action.payload);
      }
      return { ...state, categoryIdsCollector: _categoryIds };
    }
    case ActionTypes.CLEAR_FILTERS:
      return { ...state, categoryIdsCollector: [] };
    case ActionTypes.GET_ORDERS:
      return { ...state, orders: action.payload };
    case ActionTypes.SET_NEW_ORDER:
      return { ...state, newOrder: action.payload };
    case ActionTypes.SET_ORDER_COST:
      return { ...state, orderCost: action.payload };
    case ActionTypes.GET_ORDERS_HISTORY:
      return { ...state, orderHistory: action.payload };
    case ActionTypes.GET_CLIENTS_LIST:
      return { ...state, clientsList: action.payload };
    case ActionTypes.SET_FILTERED_ORDERS_HISTORY:
      return { ...state, filteredOrderHistory: action.payload };
    case ActionTypes.ADD_ORDER_IMAGES:
      return {
        ...state,
        orderImages: action.payload,
      };
    case ActionTypes.GET_CARDS:
      return { ...state, cards: action.payload };
    case ActionTypes.ENABLE_PRELOADER:
      return {
        ...state,
        isPreloader: true,
      };
    case ActionTypes.DISABLE_PRELOADER:
      return {
        ...state,
        isPreloader: false,
      };
    case ActionTypes.OPEN_LOGIN_FORM:
      return {
        ...state,
        isLoginForm: true,
      };
    case ActionTypes.CLOSE_LOGIN_FORM:
      return {
        ...state,
        isLoginForm: false,
      };
    case ActionTypes.ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case ActionTypes.SET_ACTIVE_DIALOG:
      return { ...state, activeDialog: action.payload };
    case ActionTypes.GET_GIFTS:
      return {
        ...state,
        gifts: action.payload?.gifts || [],
      };
    case ActionTypes.SELECT_GIFT_SUCCESS:
      return {
        ...state,
      };

    case ActionTypes.GET_PROJECT_ALL:
      return {
        ...state,
        projects: action.payload,
      };

    case ActionTypes.GET_CUSTOMER_BY_SSO_ID:
      return {
        ...state,
        customer: action.payload,
      };
    case ActionTypes.GET_APARTMENTS:
      return {
        ...state,
        apartments: action.payload.apartments,
        apartmentsCount: action.payload.count,
      };
    case ActionTypes.GET_APARTMENT_VOUCHERS:
      return { ...state, products: action.payload.vouchers, productCount: action.payload.count };
    case ActionTypes.GET_PROJECT_VOUCHERS:
      return {
        ...state,
        products: [...state.products, ...action.payload.vouchers],
        productCount: state.productCount + action.payload.count,
      };
    case ActionTypes.SET_LOCAL_VIRTUAL_CARD:
      return {
        ...state,
        localVirtualCards: [...state.localVirtualCards, action.payload],
      };

    default: {
      return state;
    }
  }
};

// =======================================================
type AppProviderProps = { children: ReactNode };
// =======================================================

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

// @ts-ignore
export const useAppContext = () => useContext<ContextProps>(AppContext);

// export default AppContext;
